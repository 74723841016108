import * as React from "react"
// import Homepage from "../components/homepage-new/homepage"
import Layout from "../../components/layout2023"
import Seo from "../../components/seo-no-funnelflex"
// import { Home } from "../componentsv2/home/home"
import FooterV2 from "../../componentsv2/footerv2/footerv2"
import { HomeV2 } from "../../componentsv2/homev2/homev2"

const IndexPage = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo
      title="Spiralyze: Predictive CRO Agency. 30% Guaranteed in 90 Days
    "
      showDefaultTitle={false}
      description="Get a 30% increase in conversion rates using our predictive engine that scrapes proven winners from 78,000 sites. Full-service team handles all design & dev."
      index={false} 
      follow={false}
    />
    {/* <Homepage /> */}
    {/* <Home /> */}
    <HomeV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default IndexPage
